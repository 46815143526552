import React, { useRef } from "react";
import styles from "./BottomButton.module.scss";

import soundEffect from "../../../assets/sounds/light-switch-156813.mp3";

export const BottomButton = (props) => {
  const audioRef = useRef(new Audio(soundEffect));

  const handleClick = () => {
    if (props.name === "planets" && props.page === "farm") return;

    audioRef.current.currentTime = 0;
    audioRef.current
      .play()
      .catch((err) => console.error("Audio play error:", err));
    props.setPage(props.name);
  };

  return (
    <div
      className={
        props.page === props.name
          ? `${styles.bottomButton} ${styles.bottomButtonActive}`
          : styles.bottomButton
      }
      onClick={handleClick}
    >
      <img
        src={props.imgSrc}
        alt={props.name}
        className={styles.bottomButtonImage}
      />
    </div>
  );
};
