import React from "react";
import "./Preloader.scss"; // Подключаем SCSS

const Preloader = ({ progress }) => {
  return (
    <div className="preloader-container">
      <div className="spinner"></div>
      <div className="progress-bar-container">
        <div className="progress-bar" style={{ width: `${progress}%` }}></div>
      </div>
      <p className="progress-text">Загрузка... {progress}%</p>
    </div>
  );
};

export default Preloader;
