import React, { useEffect, useState, useRef } from "react";

import bgSound from "../../assets/sounds/stranger-things-124008.mp3";

import styles from "./SystemButtons.module.scss";

const SystemButtons = ({ page }) => {
  const [isMuted, setIsMuted] = useState(true);
  const backgroundAudioRef = useRef(null);

  const [homeScreenStatus, setHomeScreenStatus] = useState("unknown"); // Статус "добавлено на главный экран"

  const tg = window?.Telegram?.WebApp;

  const addToHomeScreen = () => {
    if (tg?.addToHomeScreen) {
      tg.addToHomeScreen();
      setHomeScreenStatus("added");
    } else {
      console.warn("addToHomeScreen is not supported.");
    }
  };

  // Проверка статуса "добавлено на главный экран"
  useEffect(() => {
    if (window.location.hostname !== "localhost" && tg?.checkHomeScreenStatus) {
      tg.checkHomeScreenStatus((status) => {
        setHomeScreenStatus(status);
        console.log("Home screen status:", status);
      });
    } else {
      console.warn("Skipping checkHomeScreenStatus on localhost.");
    }
  }, [tg]);

  // ----------------BG SOUND---------------- //
  useEffect(() => {
    backgroundAudioRef.current = new Audio(bgSound);
    backgroundAudioRef.current.loop = true;

    const enableAudio = () => {
      if (!isMuted) {
        backgroundAudioRef.current
          .play()
          .catch((err) => console.error("Audio play error:", err));
      }
      document.removeEventListener("click", enableAudio);
    };

    document.addEventListener("click", enableAudio);

    return () => {
      document.removeEventListener("click", enableAudio);
      if (backgroundAudioRef.current) {
        backgroundAudioRef.current.pause();
      }
    };
  }, []);

  const handleToggleSound = () => {
    if (!backgroundAudioRef.current) return;

    if (isMuted) {
      backgroundAudioRef.current
        .play()
        .catch((err) => console.error("Audio play error:", err));
    } else {
      backgroundAudioRef.current.pause();
    }
    setIsMuted((prev) => !prev);
  };

  // ----------------BG SOUND---------------- //

  return page === "planets" ? (
    <div className={styles.actionButtons}>
      <button className={styles["volume-button"]} onClick={handleToggleSound}>
        <img
          src={
            isMuted
              ? require("../../assets/icons/volume-off.png")
              : require("../../assets/icons/volume-on.png")
          }
          alt="Volume Button"
        />
      </button>

      {(homeScreenStatus === "missed" || homeScreenStatus === "unknown") && (
        <button
          onClick={addToHomeScreen}
          className={styles["add-to-home-button"]}
        >
          <img src={require("../../assets/icons/share.png")} alt="Button" />
        </button>
      )}
    </div>
  ) : null;
};

export default SystemButtons;
